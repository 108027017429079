import goa from "../media/goa.jpg";
import leh2leh from "../media/dusty/HomePage/leh2leh.jpg";
import manali2sri from "../media/dusty/HomePage/manali2sri.jpg";
import sri2manali from "../media/dusty/HomePage/sri2manali.jpg";
import spiti from "../media/dusty/HomePage/spiti.jpg";
import sriumingmanali from "../media/dusty/HomePage/landing8.jpg";
import lehuming from "../media/dusty/HomePage/landing3.jpg";
import lehumingmanali from "../media/dusty/HomePage/landing2.jpg";

export const packageList = [
  {
    path: "leh2leh",
    packageName: "Leh - 7 Days"
  },
  {
    path: "manali2srinagar",
    packageName: "Manali to Srinagar - 11 Days"
  },
  {
    path: "srinagar2manali",
    packageName: "Srinagar to Manali - 11 Days"
  },
  {
    path: "spiti",
    packageName: "Spiti Valley - 9 Days"
  },
]

export const packagesCardInfoList = [
  {
    packageName: "Leh - 7 Days",
    description: "Embark on a breathtaking Leh to Leh biking odyssey, traversing Himalayan peaks, serene valleys, and ancient monasteries—a soul-stirring adventure awaits!",
    img: leh2leh,
    days: "7 Days",
    places: "4 Places",
    distance: "~ 900 kms",
    rating: 4.9,
    price1: "₹ 25,900",
    price2: "₹ 20,900",
    path: "leh2leh",
  },
  {
    packageName: "Srinagar to Manali (Via Leh) - 11 Days",
    description: "The one that takes you to the from scenic landscapes and stunning beauty of Kashmir to the grey mountains of Ladakh before finally ending at the evergreen hill station of Manali. Best for explorers!",
    img: sri2manali,
    days: "11 Days",
    places: "9 Places",
    distance: "~ 1700 kms",
    rating: 4.8,
    price1: "₹ 35,900",
    price2: "₹ 27,900",
    path: "srinagar2manali",
  },
  {
    packageName: "Manali to Srinagar (Via Leh) - 11 Days",
    description: "The one that takes you to the from evergreen hill station of Manali to the grey mountains of Ladakh before finally ending at the scenic landscapes and stunning beauty of Kashmir. Best for explorers!",
    img: manali2sri,
    days: "11 Days",
    places: "9 Places",
    distance: "~ 1700 kms",
    rating: 4.8,
    price1: "₹ 35,900",
    price2: "₹ 27,900",
    path: "manali2srinagar",
  },
  {
    packageName: "Spiti Valley (Manali to Manali)",
    description: "Explore Spiti Valley's rugged beauty and ancient monasteries on our curated adventure. Unforgettable landscapes await for the rider in you!",
    img: spiti,
    days: "9 Days",
    places: "6 Places",
    distance: "~ 800 kms",
    rating: 4.7,
    price1: "₹ 20,900",
    price2: "₹ 15,400",
    path: "spiti",
  },
];
